import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFields } from '../actions/fieldsActions';
import { FieldCard } from './FieldCard';
import { getSignalStatus, getModel, getModelName, getOwner } from '../utils/fieldUtils';
import '../styles/fields.css';
import { Header2 } from './Header2';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';
import { processGCM } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom';
import { getUser } from '../actions/userActions';
import { TabMenu } from 'primereact/tabmenu';
import { useSwipeable } from 'react-swipeable'; // Importa la biblioteca

export const Fields = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { email: userEmail } = useSelector((state) => state.user);
    const [selectedModel, setSelectedModel] = useState(localStorage.getItem('selectedModel') || '');
    const [activeIndex, setActiveIndex] = useState(parseInt(localStorage.getItem('activeIndex')) || 0);
    const [selectedOwner, setSelectedOwner] = useState(localStorage.getItem('selectedOwner') || '');
    const fieldListRef = useRef(null);
    const [items, setItems] = useState([
        { label: 'Nematode', value: 'nematode' },
        { label: 'Solarization', value: 'solarization' },
        { label: 'No model', value: 'no_model' }
    ]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getUser());
            try {
                const fieldsData = await getFields();
                setFields(fieldsData);

                const numNematode = fieldsData.filter((f) => getModelName(f) === 'nematode').length;
                const numSolarization = fieldsData.filter((f) => getModelName(f) === 'solarization').length;
                const numNoModel = fieldsData.filter((f) => getModelName(f) === 'no_model').length;

                setItems([
                    { label: `Nematode (${numNematode})`, value: 'nematode' },
                    { label: `Solarization (${numSolarization})`, value: 'solarization' },
                    { label: `No model (${numNoModel})`, value: 'no_model' }
                ]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();

        const requestPermissionAndRegisterSW = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
                    const token = await getToken(messaging, {
                        vapidKey: 'BHARREGztf47ESSTmbIR8yMW2qF2ZSVnuZNqrX2HplkKWO1ndN7HoDQQyr1MsZextr8A9dTZQDVnFqf1IvBwYPw',
                        serviceWorkerRegistration: registration,
                    });
                    if (token) {
                        await processGCM(token);
                    } else {
                        console.log('No registration token available.');
                    }
                }
            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };

        requestPermissionAndRegisterSW();
    }, [dispatch]);

    const handleCreateField = () => {
        navigate('/create/field/qr');
    };

    const filteredFields = fields.filter((f) => {
        const modelMatch = selectedModel ? getModelName(f) === selectedModel : true;
        const ownerMatch = selectedOwner === 'own' ? getOwner(f) === userEmail : selectedOwner === 'guest' ? getOwner(f) !== userEmail : true;
        return modelMatch && ownerMatch;
    });

    const handleTabChange = (e) => {
        const index = e.index !== undefined ? e.index : e;
        const value = items[index].value;
        setActiveIndex(index);
        localStorage.setItem('activeIndex', index);
        setSelectedModel(value);
        localStorage.setItem('selectedModel', value);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            const newIndex = (activeIndex + 1) % items.length;
            handleTabChange(newIndex);
        },
        onSwipedRight: () => {
            const newIndex = (activeIndex - 1 + items.length) % items.length;
            handleTabChange(newIndex);
        },
        delta: 100,
        trackMouse: true
    });

    return (
        <div className="field-container" {...handlers}>
            <Header2 isMain={true} />
            <div className='filter-div-container'>
                <TabMenu className="model-tabmenu" model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
            </div>
            {loading && (
                <div className="load-3">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            )}
            {error && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
            <div className='addField-div' onClick={handleCreateField}>
                <FontAwesomeIcon className='svg-settings-add' icon={faPlus} />
                <p className='addField-p'>Add field</p>
            </div>
            <div className='fieldList' ref={fieldListRef}>
                {filteredFields.map((f) => (
                    <FieldCard 
                        key={f.id}
                        fieldId={f.id}
                        fieldName={f.name} 
                        sensorNumber={f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'No sensor'}   
                        activeSensor={f.sensors.length > 0 && f.sensors[0].is_activated ? 'Active' : 'Desactive'}
                        signalSensor={f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'No Signal'}
                        model={getModel(f)}
                        ownerEmail={getOwner(f)}
                        userEmailLogin={userEmail}
                        expirationLicenseDate={f.expiration_license_date}
                        isFields={true}
                    />
                ))}
            </div>
            <div></div>
        </div>
    );
};