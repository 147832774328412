import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { Provider } from 'react-redux'; // Importa el Provider
import store from './store.js'; // Importa el store

const root = ReactDOM.createRoot(document.getElementById('root'));

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

root.render(
  <Provider store={store}> {/* Envuelve App con el Provider */}
    <App />
  </Provider>
);
