import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Configuración de i18next
i18n
  .use(HttpBackend) // Para cargar archivos JSON con las traducciones
  .use(LanguageDetector) // Detecta automáticamente el idioma
  .use(initReactI18next) // Inicializa react-i18next
  .init({
    fallbackLng: 'en', // Idioma por defecto
    debug: true, // Habilita el modo debug
    detection: {
      // Prioriza el idioma del navegador
      order: ['navigator'], 
      caches: [], // No guardo en cache
    },
    ns: ['translation'],
    interpolation: {
      escapeValue: false, // React ya maneja el escape de valores
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Ruta donde se encuentran los archivos de traducción
    },
  });

export default i18n;
