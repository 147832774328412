import React from 'react';
import '../styles/modalinstall.css'; // Asegúrate de que los estilos del modal estén bien configurados

export const ModalInstall = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p className='modal-p'>{message}</p>
                <div className="modal-buttons">
                    <button className="modal-install-button cancel-button" onClick={onClose}>Don`t show again</button>
                    <button className="modal-install-button confirm-button" onClick={onConfirm}>Remind me later</button>
                </div>
            </div>
        </div>
    );
};
