import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import '../styles/settings.css';
import nematool_logo from '../assets/nematool.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, deleteAccount } from '../actions/userActions';
import { Header2 } from './Header2';
import { ModalGeneric } from './ModalGeneric'; // Importa el componente del modal

export const Settings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false); // Estado para controlar el modal de cerrar sesión
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Estado para controlar el modal de eliminar cuenta

    const handleLogOut = () => {
        setIsLogoutModalOpen(true); // Abre el modal de cerrar sesión
    };

    const confirmLogOut = () => {
        dispatch(logout(navigate));
        setIsLogoutModalOpen(false); // Cierra el modal después de la acción
    };

    const cancelLogOut = () => {
        setIsLogoutModalOpen(false); // Cierra el modal sin cerrar sesión
    };

    const handleDeleteAccount = () => {
        setIsDeleteModalOpen(true); // Abre el modal de eliminar cuenta
    };

    const confirmDeleteAccount = () => {
        dispatch(deleteAccount(navigate));
        setIsDeleteModalOpen(false); // Cierra el modal después de la acción
    };

    const cancelDeleteAccount = () => {
        setIsDeleteModalOpen(false); // Cierra el modal sin eliminar
    };

    const redirectToTerms = () => {
        window.location.href = "https://nematool.com/Nematool-UserLicenseAgreement.pdf";
    };

    const redirectToPolicy = () => {
        window.location.href = "https://nematool.com/Nematool-DataPrivacyStatement.pdf";
    };

    return (
        <div className="settings-div">
            <Header2 />
            <p className='settings-title'>Settings</p>
            <div className="settings-image-div">
                <img 
                    src={nematool_logo}
                    alt="Profile" 
                    className="rounded-image"
                />
            </div>
            <p className='settings-version'>Nematool 2.15.19</p>
            <br />
            <div className='settings-option-div settings-option-div-up' onClick={handleLogOut}>
                <p className='settings-option-p'>Log out</p>
            </div>
            <div className='settings-option-div' onClick={handleDeleteAccount}>
                <p className='settings-option-p'>Delete account</p>
            </div>
            <br />
            <div className='settings-option-div settings-option-div-up' onClick={redirectToTerms}>
                <p className='settings-option-p'>Terms and conditions</p>
            </div>
            <div className='settings-option-div' onClick={redirectToPolicy}>
                <p className='settings-option-p'>Privacy policy</p>
            </div>

            <ModalGeneric 
                isOpen={isLogoutModalOpen} 
                onClose={cancelLogOut} 
                onConfirm={confirmLogOut}
                message="Are you sure you want to log out?"
            />

            <ModalGeneric 
                isOpen={isDeleteModalOpen} 
                onClose={cancelDeleteAccount} 
                onConfirm={confirmDeleteAccount}
                message="Are you sure you want to delete your account?"
            />
        </div>
    );
};
