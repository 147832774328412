import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getField, deleteField, getGuests } from '../actions/fieldsActions';
import { getUser } from '../actions/userActions';
import { postNematode, postSolarization, putNematode, putSolarization } from '../actions/modelActions'; 
import '../styles/field.css';
import { FieldCard } from './FieldCard';
import { getSignalStatus, getModel, getModelId, getOwner } from '../utils/fieldUtils';
import { Header } from './Header';
import { Header2 } from './Header2';
import { ModalGeneric } from './ModalGeneric';
import { ModalShared } from './ModalShared';
import { ModalInterpolate } from './ModalInterpolate';
import { LineChart } from './LineChart';
import { DateNavigator } from './DateNavigator';
import { avalibleLicence, formatDateLicence, expirationDays } from '../utils/functions';
import Map from './Map';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { SelectButton } from 'primereact/selectbutton';
import { getTemperatureData, getPredictionModel, getSolarizationData, getExcelPredictionModel, getExcelSolarizationData, getExcelTemperatureData } from '../actions/temperatureActions';
import 'chartjs-plugin-gradient'; // Importa el plugin
import { TabMenu } from 'primereact/tabmenu'; // Importa TabMenu
import { useSwipeable } from 'react-swipeable'; // Importa la biblioteca

export const Field = () => {
    const { fieldId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [errorMessage, setErrorMessage] = useState('');
    const { fieldData, loading: fieldLoading, error } = useSelector((state) => state.fields);
    const { email: userEmail, loading: userLoading } = useSelector((state) => state.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSharedUsersModalOpen, setIsSharedUsersModalOpen] = useState(false);
    const [isInterpolateModalOpen, setIsInterpolateModalOpen] = useState(false);
    const [fieldToDelete, setFieldToDelete] = useState(null);
    const [sharedUsers, setSharedUsers] = useState();
    const [initDate, setInitDate] = useState(null);
    const [lastDate, setLastDate] = useState(new Date());
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalId, setModalId] = useState('');
    const [activeTab, setActiveTab] = useState('info');

    const items = [
        { label: 'Information', value: 'info' },
        { label: 'Chart', value: 'chart' },
        { label: 'Map', value: 'map' }
    ];

    useEffect(() => {
        dispatch(getUser());
        if (fieldId) {
            dispatch(getField(fieldId));
        } else {
            console.error("El fieldId es undefined");
        }
    }, [dispatch, fieldId]);

    useEffect(() => {
        let newDateInit = new Date();

        if (fieldData) {
            if(fieldData.active_prediction_model){
                newDateInit = new Date(fieldData.active_prediction_model.started);
            }
            else if(fieldData.active_solarization){
                newDateInit = new Date(fieldData.active_solarization.started);
            }
            else{
                newDateInit.setMonth(newDateInit.getMonth() - 1);
            }
            setInitDate(newDateInit);

            setIsLoading(true);
            const timer = setTimeout(() => {
                fetchChartData(fieldData.id, getModelId(fieldData), newDateInit, lastDate);
            }, 1000);
            
            return () => clearTimeout(timer);
        }
    }, [fieldData, lastDate]);
    
    const loading = fieldLoading || userLoading; 

    const f = fieldData;

    let licenseStatus;
    if (f) {
        licenseStatus = avalibleLicence(f.expiration_license_date);
        licenseStatus = true;
    }

    let lat, lon;
    if (f && f.area && Array.isArray(f.area.coordinates) && f.area.coordinates.length === 2) {
        lon = f.area.coordinates[0];
        lat = f.area.coordinates[1];
    } else {
        lat = 0;
        lon = 0;
    }
    const ownerEmail = f ? getOwner(f) : '';

    const handleStartNematode = () => {
        if (f) {
            dispatch(postNematode(f.id));
        }
    };

    const handleStartSolarization = () => {
        if (f) {
            dispatch(postSolarization(f.id));
        }
    };

    const handleStopNematode = () => {
        if (f) {
            dispatch(putNematode(f.id, f.active_prediction_model.id));
        }
    };

    const handleStopSolarization = () => {
        if (f) {
            dispatch(putSolarization(f.id, f.active_solarization.id));
        }
    };

    const handleDeleteField = () => {
        setFieldToDelete(f);
        setModalId('delete-field');
        setModalMessage("Are you sure you want to remove this field?");
        setIsModalOpen(true);
    };

    const confirmGenericModal = async () => {
        if (modalId === 'delete-field') {
            if (fieldToDelete) {
                try {
                    const responseStatus = await deleteField(fieldToDelete.id);
                    if (responseStatus !== 200) {
                        setErrorMessage("Error, no se pudo eliminar el campo");
                    } else {
                        navigate('/fields/');
                    }
                } catch (error) {
                    setErrorMessage("Error al eliminar el campo: " + error.message);
                }
            }
        } else if (modalId === 'change-sensor') {
            navigate('/link/sensor', { state: { fieldId } });
        }
        setIsModalOpen(false);
    };

    const cancelGenericModal = () => {
        setIsModalOpen(false);
    };

    const openSharedUsersModal = async () => {
        if (f) {
            try {
                const response = await getGuests(f.id);
                setSharedUsers(response.results);
                setIsSharedUsersModalOpen(true);
            } catch (error) {
                console.log(error)
                setErrorMessage("Error al obtener los usuarios compartidos.");
            }
        }
    };

    const changeSensor = () => {
        setModalId('change-sensor');
        setModalMessage("Do you want to replace the current sensor with another? This is useful if the sensor is failing or needs to be replaced with a new one.");
        setIsModalOpen(true);
    };

    const closeSharedUsersModal = () => {
        setIsSharedUsersModalOpen(false);
    };

    const isNematodeActive = f && f.active_prediction_model;
    const isSolarizationActive = f && f.active_solarization;

    const handleMapLoad = (position) => {
        // Puedes realizar otras acciones cuando el mapa se carga
    };

    const handleTabChange = (index) => {
        setActiveTab(items[index].value);
    };

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            if (!eventData.event.target.closest('.map-div')) {
                const newIndex = (items.findIndex(item => item.value === activeTab) + 1) % items.length;
                handleTabChange(newIndex);
            }
        },
        onSwipedRight: (eventData) => {
            if (!eventData.event.target.closest('.map-div')) {
                const newIndex = (items.findIndex(item => item.value === activeTab) - 1 + items.length) % items.length;
                handleTabChange(newIndex);
            }
        },
        trackMouse: true,
        delta: 100 // Ajusta el delta según sea necesario
    });

    if (loading) {
        return (
            <div {...handlers}>
                <Header2 />
                <div className="load-3">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div {...handlers}>
                <Header2 />
                <p className='field-error'>Error: {error}</p>
            </div>
        );
    }

    const handleClickBuyLicense = (sensorNumber) => {
        navigate('/create/field/license', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const handleInterpolate = async () => {
        setIsInterpolateModalOpen(true);
    };

    const closeInterpolateModal = () => {
        setIsInterpolateModalOpen(false);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatDateChart = (date, interval) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        if (interval === 'H') {
            return `${day}/${month}/${year} ${hour}:00`;
        } else {
            return `${day}/${month}/${year}`;
        }
    };

    const fetchChartData = async (fieldId, model, initDate, lastDate) => {
        setIsLoading(true);
        const formattedInitDate = formatDate(initDate);
        const formattedLastDate = formatDate(lastDate);
        let modelName = "Nematode";
        let modelData = [];
        let interval = 'D';
        if (model && model[0] === "S"){
            interval = "H";
            modelName = "Solarization";
        }

        const temperatureData = await getTemperatureData(fieldId, interval, formattedInitDate, formattedLastDate);

        const labels = Object.keys(temperatureData).map(dateString => formatDateChart(new Date(dateString), interval));
        const temperatureDataset = Object.values(temperatureData).map(entry => entry.mean);

        let modelDataset = [];
        if (model && model[0] === "N"){  
            modelData = await getPredictionModel(model[1], formattedInitDate, formattedLastDate);
            modelDataset = labels.map(label => {
                const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.ratio_eggs * 100 : null;
            });
        }
        else if (model && model[0] === "S"){
            modelData = await getSolarizationData(fieldId, formattedInitDate, formattedLastDate);
            modelDataset = labels.map(label => {
                const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.degrees : null;
            });
        }

        const getColor = (value) => {
            if (model && model[0] === "S"){
                return '#8AE640';
            }
            if (value == null) return 'transparent';
            if(value > 130) return '#42A5F5';
            if(value > 100) return '#E03642';
            if(value > 80) return '#F9794A';
            return '#8AE640';
        };

        const datasets = [
            {
                label: 'Average Temperature',
                fill: true,
                borderColor: '#8A99AD',
                tension: .4,
                data: temperatureDataset,
                yAxisID: 'y',
                backgroundColor: 'transparent',
                pointBackgroundColor: '#8A99AD',
                pointBorderColor: '#8A99AD',
                pointRadius: 1,
                pointHoverRadius: 5,
            }
        ];

        if (model) {
            datasets.push({
                label: modelName,
                fill: true,
                borderColor: modelDataset.map((value, index) => getColor(modelDataset[index + 1] || value)),
                tension: .4,
                data: modelDataset.map(value => (value > 100 ? 100 : value)),
                yAxisID: 'y1',
                backgroundColor: modelDataset.map((value, index) => `${getColor(modelDataset[index + 1] || value)}33`),
                pointBackgroundColor: modelDataset.map(value => getColor(value)),
                pointBorderColor: modelDataset.map(value => getColor(value)),
                pointRadius: 1,
                pointHoverRadius: 5,
                segment: {
                    borderColor: ctx => getColor(modelDataset[ctx.p1DataIndex]),
                    backgroundColor: ctx => `${getColor(modelDataset[ctx.p1DataIndex])}33`
                }
            });
        }

        setChartData({
            labels,
            datasets
        });
        setIsLoading(false);
    };

    const getLightTheme = () => {
        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    }
                }
            }
        };

        return {
            multiAxisOptions
        }
    }
    const { multiAxisOptions } = getLightTheme();

    const downloadModelData = async (model) => {
        let response;
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "N"){
            response = await getExcelPredictionModel(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else if (model && model[0] === "S"){
            response = await getExcelSolarizationData(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else{
            return;
        }
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-model.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const downloadTemperatureData = async (model) => {
        let interval = 'H';
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "N"){
            interval = 'D';
        }
        const response = await getExcelTemperatureData(f.id, interval, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-temperature.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    return (
        <div className="field-details" {...handlers}>
            <Header2 goBack={'/fields'}/>
            {f && (
                <>
                    <TabMenu 
                        className="field-tabmenu"
                        model={items}
                        activeIndex={items.findIndex(item => item.value === activeTab)}
                        onTabChange={(e) => handleTabChange(e.index)}
                    />
                    {activeTab === 'info' && (
                        <div className='info-div'>
                            <div className='field-card-div'>
                                <FieldCard
                                    fieldId={f.id}
                                    fieldName={f.name} 
                                    sensorNumber={f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'No sensor'}   
                                    activeSensor={f.sensors.length > 0 && f.sensors[0].is_activated ? 'Active' : 'Desactive'}
                                    signalSensor={f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'No Signal'}
                                    model={getModel(f)}
                                    ownerEmail={getOwner(f)}
                                    userEmailLogin={userEmail}
                                    expirationLicenseDate={f.expiration_license_date}
                                />
                            </div>
                            {userEmail === ownerEmail && (
                                <div className='field-buttons-div'>
                                    <div>
                                        {errorMessage && <strong><p className="form-field-message">{errorMessage}</p></strong>}
                                    </div>
                                    {(licenseStatus == null || !licenseStatus) &&f.sensors.length > 0  && (
                                        <div className='expired-license-container'>
                                            {licenseStatus == null ? (
                                                <p id='expired-license-text'>No license</p>
                                            ) : (
                                                <p id='expired-license-text'>License expired {expirationDays(f.expiration_license_date)} days ago</p>
                                            )}
                                            <button className="field-button" onClick={() => handleClickBuyLicense(f.sensors[0].tst_serial_number)}>
                                                Assign license
                                            </button>
                                        </div>
                                    )}
                                    {f.sensors.length <= 0  && (
                                        <div className='expired-license-container'>
                                            <button className="field-button" onClick={handleClickLinkSensor}>
                                                Link sensor
                                            </button>
                                        </div>
                                    )}
                                    {licenseStatus && (
                                        <div className='boton-field-div'>
                                            {isNematodeActive && (
                                            <>
                                                <button
                                                    className='field-button'
                                                    onClick={handleStopNematode}
                                                >
                                                    Stop nematode
                                                </button>
                                                <button
                                                    className='field-button'
                                                    onClick={handleInterpolate}
                                                >
                                                    Interpolate
                                                </button>
                                            </>
                                        )}
                                        {isSolarizationActive && (
                                            <button 
                                                className='field-button'
                                                onClick={handleStopSolarization}
                                            >
                                                Stop solarization
                                            </button>
                                        )}
                                        {!isNematodeActive && !isSolarizationActive && (
                                            <>
                                                <button 
                                                    className='field-button' 
                                                    onClick={handleStartNematode}
                                                >
                                                    Start nematode
                                                </button>
                                                <button 
                                                    className='field-button'
                                                    onClick={handleStartSolarization}
                                                >
                                                    Start solarization
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    )}
                                    <div className='boton-field-div'>
                                        <button 
                                            className='field-button' 
                                            onClick={openSharedUsersModal}
                                        >
                                            Shared users
                                        </button>
                                        <button 
                                            className='delete-field-button' 
                                            onClick={handleDeleteField}
                                        >
                                            Delete field
                                        </button>
                                    </div>
                                    <div className='boton-field-div'>
                                        <button 
                                            className='field-button' 
                                            onClick={changeSensor}
                                        >
                                            Change sensor
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {activeTab === 'chart' && (
                        <div className='chart-div'>
                            <div className='calendar-div'>
                                <Calendar 
                                    id='init-date' 
                                    value={initDate} 
                                    onChange={(e) => {
                                        setInitDate(e.value);
                                        fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                    }} 
                                    showIcon 
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput
                                />
                                <Calendar 
                                    id='last-date' 
                                    value={lastDate} 
                                    onChange={(e) => {
                                        setLastDate(e.value);
                                        fetchChartData(f.id, getModelId(f), initDate, e.value);
                                    }} 
                                    showIcon 
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput 
                                />
                            </div>
                            {isLoading ? (
                                <div className="load-3 load-chart">
                                    <div className="line"></div>
                                    <div className="line"></div>
                                    <div className="line"></div>
                                </div>
                            ) : (
                                <Chart type="line" data={chartData} options={multiAxisOptions} />
                            )}
                            <div className='boton-field-div'>
                                {(isNematodeActive || isSolarizationActive) && (
                                    <>
                                        <button 
                                            className='field-button' 
                                            onClick={() => downloadModelData(getModelId(f))}
                                        >
                                            Download model data
                                        </button>
                                    </>
                                )}
                                <button 
                                    className='field-button' 
                                    onClick={() => downloadTemperatureData(getModelId(f))}
                                >
                                    Download temperature data
                                </button>
                            </div>
                        </div>
                    )}
                    {activeTab === 'map' && (
                        <div className='map-div'>
                            <Map latitude={lat} longitude={lon} onMapLoad={handleMapLoad} clickable={false} />
                        </div>
                    )}
                </>
            )}

            {f && userEmail === ownerEmail && (
                <>
                    <ModalGeneric 
                        isOpen={isModalOpen} 
                        onClose={cancelGenericModal} 
                        onConfirm={confirmGenericModal}
                        message={modalMessage}
                    />

                    <ModalShared 
                        isOpen={isSharedUsersModalOpen} 
                        onClose={closeSharedUsersModal} 
                        sharedUsers={sharedUsers}
                        fieldId={f.id}
                        setSharedUsers={setSharedUsers}
                    />

                    <ModalInterpolate 
                        isOpen={isInterpolateModalOpen} 
                        onClose={closeInterpolateModal}
                        interpolationPeriod={f.sensors[0] ? f.sensors[0].interpolation_period : null}
                        sensorId={f.sensors[0] ? f.sensors[0].id : null}
                    />
                </>
            )}
                
        </div>
    );
};