import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { Header2 } from './Header2';
import { fetchNotifications } from '../actions/fieldsActions';
import '../styles/notifications.css';

export const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para el loading
    const navigate = useNavigate(); // Inicializa el hook useNavigate

    useEffect(() => {
        const getNotifications = async () => {
            setLoading(true);
            const data = await fetchNotifications();
            setNotifications(data.results || []);
            setLoading(false);
        };

        getNotifications();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes}`;
        return formattedDate;
    };

    return (
        <div>
            <Header2 />
            <div className='notifications'>
                {loading ? (
                    <div className="load-3">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                ) : notifications.length === 0 ? (
                    <p className='notification-text'>No notifications available.</p>
                ) : (
                    notifications.map((notification, index) => (
                        <div
                            key={index}
                            onClick={() => navigate(`/field/${notification.event.field_id}`)} // Usa navigate
                            className='notification'
                        >
                            <p className='notification-title'>{notification.event.title}</p>
                            <p className='notification-created'>{formatDate(notification.event.created_original)}</p>
                            <p className='notification-text'>{notification.event.text}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
