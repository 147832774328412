import React, { useState } from 'react';
import { interpolateData } from '../actions/fieldsActions';
import { useNavigate } from 'react-router-dom';
import '../styles/modalinterpolate.css'; // Asegúrate de tener estilos para el modal

export const ModalInterpolate = ({ isOpen, onClose, interpolationPeriod, sensorId }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState(interpolationPeriod);
    const [errorMessage, setErrorMessage] = useState('');

    if (!isOpen) return null;

    const handleChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (newValue >= 0 && newValue <= 10) {
            setValue(newValue);
        }
    };

    const handleAccept = async () => {
        const response = await interpolateData(value, sensorId);
        if (typeof response === 'string') {
            setErrorMessage(response);
        } else {
            window.location.reload(true);
        }
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="number-picker-container">
                    {errorMessage && <strong><p className="error-message">{errorMessage}</p></strong>}
                    <h3>Number of days to interpolate:</h3>
                    <input
                        type="number"
                        value={value}
                        min={0}
                        max={10}
                        onChange={handleChange}
                    />
                </div>
                <div className="modal-buttons">
                    <button className="modal-button cancel-button" onClick={handleCancel}>Cancel</button>
                    <button className="modal-button confirm-button" onClick={handleAccept}>Accept</button>
                </div>
            </div>
        </div>
    );
};
