import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions/userActions';
import bayer_logo from '../assets/bayer-logo.svg';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button'; // Importa el componente Button de PrimeReact
import '../styles/login.css';
import { useTranslation } from 'react-i18next';

export const Login = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, error } = useSelector((state) => state.user);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = () => {
        dispatch(login(email, password, navigate));
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='login-body-container'>
            <div className="login-logo-container">
                <img src={bayer_logo} alt="Bayer Logo" className="login-logo" />
            </div>
            <div className="login-container">
                <div className="login-logo-container login-logo-nematool">
                    <img src={nematool_logo_RGB} alt="Nematool Logo" className="login-svg_nematool" />
                </div>
                <p className='login-p'>{t('login.info_message')}</p>
                <p style={{ color: 'red', minHeight: '2.5em' }}>
                    <strong>{typeof error === 'string' ? t(`login.${error}`) : ''}</strong>
                </p>
                <form>
                    <div className="login-form-group">
                        <label htmlFor="email">{t('login.email')}</label>
                        <input
                            className='login-input'
                            type="text"
                            id="email"
                            name="email"
                            placeholder={t('login.placeholder_email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group password-input-container">
                        <label htmlFor="password">{t('login.password')}</label>
                        <input
                            className='login-input'
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder={t('login.placeholder_password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span onClick={toggleShowPassword} className="password-toggle-icon">
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className='div-login-button'>
                        <Button className='login-button' onClick={handleSubmit} disabled={loading}>
                            {loading ? t('login.loading_message') : t('login.login_message')}
                        </Button>
                    </div>
                    <div id='div-login-register'>
                        <a id='login-click-here' href="/recoverPassword">{t('login.recover_message')}</a>
                    </div>
                    <div id='div-login-register'>
                        <a>{t('login.register_message')} <a id='login-click-here' href="/register">{t('login.here_button')}</a></a>
                    </div>
                </form>
                <div className="login-footer">
                    <a href="https://nematool.com/Nematool-UserLicenseAgreement.pdf" id='login-termino'>{t('login.terms_conditions_message')}</a>
                    <a href="https://nematool.com/Nematool-DataPrivacyStatement.pdf">{t('login.privacy_policy_message')}</a>
                </div>
            </div>
            <div className="login-copy">
                &copy; Bayer CropScience S.L.
            </div>
        </div>
    );
};