import React from 'react';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Importa hooks de Redux
import { logout } from '../actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faBell, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import '../styles/header2.css';

export const Header2 = ({ isMain = false, goBack = null }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSettings = () => {
        navigate('/settings');
    };

    const handleGoBack = () => {
        if (goBack == null) {
            navigate(-1);
        } else {
            navigate(goBack);
        }
    };

    const handleNotifications = () => {
        navigate('/notifications');
    };

    return (
        <div className='logo-div'>
            <FontAwesomeIcon className='svg-settings' style={isMain ? {} : {paddingRight: '8.2px'}} icon={isMain ? faGear : faArrowLeft} onClick={isMain ? handleSettings : handleGoBack} />
            <img src={nematool_logo_RGB} alt="Bayer Logo" className="header-logo" />
            <FontAwesomeIcon className='svg-settings' icon={faBell} style={{ visibility: isMain ? 'visible' : 'hidden' }} onClick={handleNotifications} />
        </div>
    );
};
