import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { RecoverPassword } from './components/RecoverPassword';
import { Fields } from './components/Fields';
import { Field } from './components/Field';
import { Settings } from './components/Settings';
import { QRScan } from './components/QRScan';
import { FormField } from './components/FormField';
import PrivateRoute from './components/PrivateRoute';
import { AddLicense } from './components/AddLicense';
import { LinkSensor } from './components/LinkSensor';
import { Notifications } from './components/Notifications';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.css';
import { ModalInstall } from './components/ModalInstall';
import { ModalNetwork } from './components/ModalNetwork';
import './i18n';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/Service-worker.js')
        .then(() => console.log('Service Worker registrado'))
        .catch((error) => console.error('Error al registrar el Service Worker:', error));
    }
    
    const modalStatus = localStorage.getItem('dontShowModal');
    if (!modalStatus) {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    const checkNetworkConnection = async () => {
      try {
        const response = await fetch('https://www.google.com', { mode: 'no-cors' });
        if (response) {
          setIsNetworkModalOpen(false);
        }
      } catch (error) {
        setIsNetworkModalOpen(true);
      }
    };

    checkNetworkConnection();

    const interval = setInterval(checkNetworkConnection, 6000);

    return () => clearInterval(interval);
  }, []);

  const handleDontShowAgain = () => {
    localStorage.setItem('dontShowModal', 'true');
    setIsModalOpen(false);
  };

  const handleRemindMeLater = () => {
    setIsModalOpen(false);
  };

  return (
    <Router>
      <PrimeReactProvider>
        <ModalInstall
          isOpen={isModalOpen}
          message={"Install the app to get the best experience"}
          onClose={handleDontShowAgain}
          onConfirm={handleRemindMeLater}
        />
        <ModalNetwork
          isOpen={isNetworkModalOpen}
          message={"No internet connection. Please check your network settings."}
          onClose={() => setIsNetworkModalOpen(false)}
          onConfirm={() => setIsNetworkModalOpen(false)}
        />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recoverPassword" element={<RecoverPassword />} />
          <Route path="/fields" element={<PrivateRoute element={Fields} />} />
          <Route path="/settings" element={<PrivateRoute element={Settings} />} />
          <Route path="/field/:fieldId" element={<PrivateRoute element={Field} />} />
          <Route path="/create/field/qr" element={<PrivateRoute element={QRScan} />} />
          <Route path="/create/field/license" element={<PrivateRoute element={AddLicense} />} />
          <Route path="/create/field/form" element={<PrivateRoute element={FormField} />} />
          <Route path="/link/sensor" element={<PrivateRoute element={LinkSensor} />} />
          <Route path="/notifications" element={<PrivateRoute element={Notifications} />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </PrimeReactProvider>
    </Router>
  );
}

export default App;
