const apiUrl = process.env.REACT_APP_API_URL;

export const login = (email, password, navigate) => async (dispatch) => {
    dispatch({ type: 'USER_REQUEST' });

    try {
        const response = await fetch(`${apiUrl}/api/v1/auth/jwt/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, grant_type: "password" }),
        });

        if (response.ok) {
            const data = await response.json();
            
            // Almacena el token en localStorage
            localStorage.setItem('authToken', data.access_token);

            // Despacha la acción de éxito con la información del usuario
            dispatch({ type: 'USER_SUCCESS', payload: data });

            navigate('/fields');

        } else {
            if (response.status === 401) {
                dispatch({ type: 'USER_FAILURE', payload: "incorrect_credentials" });
            }
            else{
                dispatch({ type: 'USER_FAILURE', payload: "unknown_error" });
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        // Despacha la acción de fallo
        dispatch({ type: 'USER_FAILURE', payload: error.message });
    }
};

export const register = async(email, password, receive_advertising, navigate) => {
    const localtoken = localStorage.getItem('authToken');
    const first_name = ""
    const last_name = ""
    try {
        const response = await fetch(`${apiUrl}/api/v1/users/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, first_name, last_name, password, receive_advertising }),
        });

        if (response.ok) {
            const data = await response.json();
            return data
        } else {
            if(response.status === 422){
                return "The email format is not valid."
            }
            if(response.status === 409){
                return "Email already exists."
            }
            if(response.status === 429){
                return "User creation limit reached, wait one hour."
            }
            return "Unknown error"
        }
    } catch (error) {
        console.error('Error en la petición', error);
    }
};

export const rememberPassword = async(email, password, receive_advertising, navigate) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/request_restore_code/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const data = await response.json();
            return data
        } else {
            if(response.status === 404){
                return "Email does not exist."
            }
            if(response.status === 429){
                return "Limit reached, wait one hour."
            }
            return "Unknown error"
        }
    } catch (error) {
        console.error('Error en la petición', error);
    }
};

export const logout = (navigate) => async (dispatch) => {
    dispatch({ type: 'USER_REQUEST' });
    const localtoken = localStorage.getItem('authToken');
    if (localtoken){
        localStorage.removeItem('authToken');
        dispatch({ type: 'USER_SUCCESS', payload: "Exito" });
        navigate('/login');
    }else{
        dispatch({ type: 'USER_FAILURE', payload: "Error" });
    }
};

export const deleteAccount = (navigate) => async (dispatch) => {
    dispatch({ type: 'USER_REQUEST' });

    try {
        const response = await fetch(`${apiUrl}/api/v1/users/me/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });

        if (response.ok) {
            localStorage.removeItem('authToken');
            dispatch({ type: 'USER_SUCCESS', payload: "Exito" });
            navigate('/login');
        } else {
            console.log('No se ha podido eliminar la cuenta');
        }
    } catch (error) {
        console.error('No se ha podido eliminar la cuenta:', error);
    }
};

export const getUser = () => async (dispatch) => {
    const localtoken = localStorage.getItem('authToken');
    dispatch({ type: 'USER_REQUEST' });

    try {
        const response = await fetch(`${apiUrl}/api/v1/users/me/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            dispatch({ type: 'USER_ME_SUCCESS', payload: data });
        } else {
            const errorData = await response.json();
            dispatch({ type: 'FIELDS_FAILURE', payload: errorData.error || "Error desconocido" });
        }
    } catch (error) {
        dispatch({ type: 'USER_FAILURE', payload: error.message || "Error de red" });
    }
};

export const processGCM = async (token) => {
    const localtoken = localStorage.getItem('authToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }
    
    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/${token}/profile/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            }
        });

        const data = await response.json();

        if (data.exist){
            updateGCM(token)
        }else{
            sendGCM(token)
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const sendGCM = async (token) => {
    const localtoken = localStorage.getItem('authToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }
    
    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localtoken}`,
            },
            body: JSON.stringify({
                registration_id: token, 
                cloud_message_type: "GCM", 
                application_id: "nematool.web"
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error response from server:', errorData);
            throw new Error('Failed to send FCM token to server');
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const updateGCM = async (token) => {
    const localtoken = localStorage.getItem('authToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }
    
    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/${token}/profile/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            }
        });

        if (response.status === 404) {
            sendGCM(token)
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const sendPushNotification = async (fieldId, title, body) => {
    try {
        const response = await fetch(`${apiUrl}/api/v1/notification/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Indicamos que el contenido es JSON
                'Authorization': `Bearer ${localStorage.getItem('authToken')}` // Token de autenticación, si es necesario
            },
            body: JSON.stringify({ // Convertimos el objeto a JSON antes de enviarlo
                title: title,
                body: body
            }),
        });

        if (!response.ok) {
            console.error('Error enviando la notificación push:', await response.text());
        } else {
            console.log('Notificación push enviada con éxito');
        }
    } catch (error) {
        console.error('Error al enviar la notificación push:', error);
    }
};