import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { register } from '../actions/userActions';
import bayer_logo from '../assets/bayer-logo.svg';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/login.css';
import '../styles/register.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import isValidPhoneNumber, { parsePhoneNumberFromString } from 'libphonenumber-js';

export const Register = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [error, setError] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [cifNif, setCifNif] = useState('');
    const [loyaltyProgramId, setLoyaltyProgramId] = useState('');
    const [address, setAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [country, setCountry] = useState('es'); // Estado para el país seleccionado
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const { loading } = useSelector((state) => state.user);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        console.log(mobile);

        if (name === ''){
            setError('Complete the name.');
            return;
        }

        if (surname === ''){
            setError('Complete the surname.');
            return;
        }

        if (email === ''){
            setError('Complete the email.');
            return;
        }

        if (password === ''){
            setError('Complete the password.');
            return;
        }

        if (password !== repeatPassword) {
            setError('The passwords do not match.');
            return;
        }

        if (address === ''){
            setError('Complete the address.');
            return;
        }

        if (mobile !== '' && !isValidPhoneNumber(mobile, country.toUpperCase())) {
            setError('The phone number is invalid.');
            return;
        }

        console.log(name, surname, email, cifNif, loyaltyProgramId, address, mobile, password, repeatPassword, isCheckboxChecked );
        return;
        
        const response = await register({ name, surname, email, cifNif, loyaltyProgramId, address, mobile, password, repeatPassword, isCheckboxChecked }, navigate);
        if (typeof response === 'string') {
            setError(response);
        } else {
            navigate('/login');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

const handlePhoneChange = (value, country) => {
    const newValue = value.slice(country.dialCode.length);
    console.log(newValue);
    setCountry(country.countryCode.toLowerCase()); // Actualizar el estado del país seleccionado
};

    return (
        <div className='register-body-container'>
            <div className="login-logo-container">
                <img src={bayer_logo} alt="Bayer Logo" className="login-logo" />
            </div>
            <div className="login-container">
                <div className="login-logo-container login-logo-nematool">
                    <img src={nematool_logo_RGB} alt="Nematool Logo" className="login-svg_nematool" />
                </div>
                {error && <p style={{ color: 'red' }}><strong>{error}</strong></p>}
                <form>
                    <div className="login-form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            className='login-input'
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="surname">Surname:</label>
                        <input
                            className='login-input'
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="Surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="email">Write your email address:</label>
                        <input
                            className='login-input'
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="cifNif">CIF/NIF (Optional):</label>
                        <input
                            className='login-input'
                            type="text"
                            id="cifNif"
                            name="cifNif"
                            placeholder="CIF/NIF"
                            value={cifNif}
                            onChange={(e) => setCifNif(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="loyaltyProgramId">ID loyalty program (Optional):</label>
                        <input
                            className='login-input'
                            type="text"
                            id="loyaltyProgramId"
                            name="loyaltyProgramId"
                            placeholder="ID loyalty program"
                            value={loyaltyProgramId}
                            onChange={(e) => setLoyaltyProgramId(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="address">Complete address:</label>
                        <input
                            className='login-input'
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Complete address"
                            value={address}
                            onChange={(e) => {
                                setAddress(e.target.value);
                                // Llamada a la API de Google Maps para normalizar la dirección
                                /*
                                const normalizeAddress = async (address) => {
                                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GMAPS_API_KEY}`);
                                    const data = await response.json();
                                    if (data.results && data.results.length > 0) {
                                        const normalizedAddress = data.results[0].formatted_address;
                                        setAddress(normalizedAddress);
                                    } else {
                                        setAddress(address);
                                    }
                                };
                                normalizeAddress(e.target.value);*/
                            }}
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="mobile">Mobile (Optional):</label>
                        <PhoneInput
                            placeholder='+34 --- --- ---'
                            country={country} // Usar el estado del país seleccionado
                            value={mobile}
                            onChange={handlePhoneChange}
                        />
                    </div>
                    <div className="login-form-group password-input-container">
                        <label htmlFor="password">Choose a password:</label>
                        <input
                            className='login-input'
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span onClick={toggleShowPassword} className="password-toggle-icon">
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="login-form-group password-input-container">
                        <label htmlFor="repeatPassword">Confirm your password:</label>
                        <input
                            className='login-input'
                            type={showRepeatPassword ? "text" : "password"}
                            id="repeatPassword"
                            name="repeatPassword"
                            placeholder="Repeat your password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        <span onClick={toggleShowRepeatPassword} className="password-toggle-icon">
                            <FontAwesomeIcon icon={showRepeatPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="login-form-group checkbox-container">
                        <input
                            type="checkbox"
                            id="lorem-checkbox"
                            name="lorem-checkbox"
                            className="custom-checkbox"
                            checked={isCheckboxChecked}
                            onChange={(e) => setIsCheckboxChecked(e.target.checked)} // Actualizar el estado del checkbox
                        />
                        <label id='label-checkbox' htmlFor="lorem-checkbox">
                            Yes, I wish to receive commercial information from Bayer Cropscience, S.L.
                        </label>
                    </div>
                    <div className='div-login-button'>
                        <button className='login-button' onClick={handleSubmit} disabled={loading}>
                            {loading ? 'Cargando...' : 'Registrar'}
                        </button>
                    </div>
                    <div id='div-login-register'>
                        <a>¿Ya tienes una cuenta? <a id='login-click-here' href="/login">Inicia sesión</a></a>
                    </div>
                </form>
                <div className="login-footer">
                    <a href="https://nematool.com/Nematool-UserLicenseAgreement.pdf" id='terminos'>Términos y condiciones</a>
                    <a href="https://nematool.com/Nematool-DataPrivacyStatement.pdf">Política de privacidad</a>
                </div>
            </div>
            <div className="login-copy">
                &copy; Bayer CropScience S.L.
            </div>
        </div>
    );
};
