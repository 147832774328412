import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Header2 } from './Header2';
import { Html5Qrcode } from 'html5-qrcode';
import '../styles/linksensor.css';
import { isValidSerialNumber, patchSensor } from '../actions/fieldsActions';

export const LinkSensor = () => {
    const dispatch = useDispatch();
    const [qrResult, setQrResult] = useState('');
    const [scanning, setScanning] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const fieldId = location.state?.fieldId;

    useEffect(() => {
        const scanner = new Html5Qrcode("reader");
        setHtml5QrCode(scanner);

        return () => {
            if (scanner.isScanning) {  // Verifica si el escáner está en funcionamiento
                scanner.stop().catch((err) => console.error("Error al detener el escaneo:", err));
            }
        };
    }, [dispatch]);

    const startScan = () => {
        if (html5QrCode && !scanning) {
            setScanning(true);
            setErrorMessage('');
    
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const qrboxSize = Math.min(viewportWidth, viewportHeight) * 0.8; // 80% del tamaño más pequeño
    
            html5QrCode.start(
                { facingMode: "environment" },
                {
                    fps: 10,
                    qrbox: { width: qrboxSize, height: qrboxSize }
                },
                (decodedText) => {
                    setQrResult(decodedText);
                    html5QrCode.stop()
                        .then(() => {
                            setScanning(false);
                        })
                        .catch((err) => console.error("Error al detener el escaneo:", err));
                }
            ).catch((err) => {
                console.error(`Error inicializando el escaneo: ${err}`);
                setErrorMessage('Error inicializando el escaneo. Por favor, inténtalo de nuevo.');
                setScanning(false);
            });
        }
    };
    
    const linkSensor = async () => {
        try {
            const isValid = await isValidSerialNumber(qrResult);
            if (typeof isValid === 'string') {
                setErrorMessage(isValid);
            } else {
                let sensorId = isValid.results[0].id
                await patchSensor(sensorId, fieldId);
                navigate(`/field/${fieldId}`);
            }
        } catch (error) {
            setErrorMessage("An error occurred while validating the serial number. Try again.");
        }
    };

    const handleInputChange = (e) => {
        setQrResult(e.target.value);
    };

    return (
        <div>
            <Header2 />
            <div className={`qr-div ${scanning ? 'scanning-active' : ''}`}>
                <h2>Enter the serial number:</h2>
                <input 
                    className='qr-input'
                    type="text" 
                    value={qrResult} 
                    onChange={handleInputChange} 
                    placeholder="Scan or enter serial number"
                />
                <div>
                    <button
                        className="qr-button"
                        onClick={startScan}
                        style={{ display: scanning ? 'none' : 'block' }}
                    >
                        {scanning ? "" : "Start scan with QR"}
                    </button>
                    <button
                        className="qr-button"
                        onClick={linkSensor}
                    >
                        Link sensor
                    </button>
                </div>
                {errorMessage && <strong><p className="error-message">{errorMessage}</p></strong>}
                <div id="reader" className={`reader ${scanning ? 'fullscreen' : ''}`}></div>
            </div>
        </div>
    );
};
